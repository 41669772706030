/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { estimateFixedArraySize as n, estimateNumberByteSize as r } from "./byteSizeEstimations.js";
import "./has.js";
function t(n) {
  return n instanceof ArrayBuffer;
}
function o(n) {
  return "Int8Array" === n?.constructor?.name;
}
function u(n) {
  return "Uint8Array" === n?.constructor?.name;
}
function e(n) {
  return "Uint8ClampedArray" === n?.constructor?.name;
}
function c(n) {
  return "Int16Array" === n?.constructor?.name;
}
function i(n) {
  return "Uint16Array" === n?.constructor?.name;
}
function a(n) {
  return "Int32Array" === n?.constructor?.name;
}
function f(n) {
  return "Uint32Array" === n?.constructor?.name;
}
function s(n) {
  return "Float32Array" === n?.constructor?.name;
}
function m(n) {
  return "Float64Array" === n?.constructor?.name;
}
function y(n) {
  return !("buffer" in n);
}
function A(...t) {
  return t.reduce((t, o) => t + (o ? y(o) ? n(o, r()) : o.byteLength + p : 0), 0);
}
const p = 145,
  U = 1024;
function b(n) {
  return m(n) || s(n) || a(n) || c(n) || o(n);
}
function l(n) {
  return m(n) || s(n);
}
function I(n) {
  return m(n) ? 179769e303 : s(n) ? 3402823e32 : f(n) ? 4294967295 : i(n) ? 65535 : u(n) || e(n) ? 255 : a(n) ? 2147483647 : c(n) ? 32767 : o(n) ? 127 : 256;
}
export { A as estimateSize, y as isArray, t as isArrayBuffer, l as isFloat, s as isFloat32Array, m as isFloat64Array, c as isInt16Array, a as isInt32Array, o as isInt8Array, b as isSigned, i as isUint16Array, f as isUint32Array, u as isUint8Array, e as isUint8ClampedArray, I as maximumValue, U as nativeArrayMaxSize, p as typedArrayStaticSize };