/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
let e;
function has(a) {
  return "function" == typeof e[a] ? e[a] = e[a](globalThis) : e[a];
}
e = globalThis.dojoConfig?.has || globalThis.esriConfig?.has ? {
  ...globalThis.dojoConfig?.has,
  ...globalThis.esriConfig?.has
} : {}, has.add = (a, d, o, r) => ((r || void 0 === e[a]) && (e[a] = d), o && has(a)), has.cache = e, has.add("big-integer-warning-enabled", !0), has.add("esri-deprecation-warnings", !0), has.add("esri-tests-disable-screenshots", !1), has.add("esri-tests-use-full-window", !1), has.add("esri-tests-post-to-influx", !0), (() => {
  has.add("host-webworker", void 0 !== globalThis.WorkerGlobalScope && self instanceof globalThis.WorkerGlobalScope);
  const e = "undefined" != typeof window && "undefined" != typeof location && "undefined" != typeof document && window.location === location && window.document === document;
  if (has.add("host-browser", e), has.add("host-node", "object" == typeof globalThis.process && globalThis.process.versions?.node && globalThis.process.versions.v8), has.add("dom", e), has("host-browser")) {
    const e = navigator,
      a = e.userAgent,
      d = e.appVersion,
      o = parseFloat(d);
    if (has.add("wp", parseFloat(a.split("Windows Phone")[1]) || void 0), has.add("msapp", parseFloat(a.split("MSAppHost/")[1]) || void 0), has.add("khtml", d.includes("Konqueror") ? o : void 0), has.add("edge", parseFloat(a.split("Edge/")[1]) || void 0), has.add("opr", parseFloat(a.split("OPR/")[1]) || void 0), has.add("webkit", !has("wp") && !has("edge") && parseFloat(a.split("WebKit/")[1]) || void 0), has.add("chrome", !has("edge") && !has("opr") && parseFloat(a.split("Chrome/")[1]) || void 0), has.add("android", !has("wp") && parseFloat(a.split("Android ")[1]) || void 0), has.add("safari", !d.includes("Safari") || has("wp") || has("chrome") || has("android") || has("edge") || has("opr") ? void 0 : parseFloat(d.split("Version/")[1])), has.add("mac", d.includes("Macintosh")), !has("wp") && /(iPhone|iPod|iPad)/.test(a)) {
      const e = RegExp.$1.replace(/P/, "p"),
        d = /OS ([\d_]+)/.test(a) ? RegExp.$1 : "1",
        o = parseFloat(d.replace(/_/, ".").replaceAll("_", ""));
      has.add(e, o), has.add("ios", o);
    }
    has("webkit") || (!a.includes("Gecko") || has("wp") || has("khtml") || has("edge") || has.add("mozilla", o), has("mozilla") && has.add("ff", parseFloat(a.split("Firefox/")[1] || a.split("Minefield/")[1]) || void 0));
  }
})(), (() => {
  if (globalThis.navigator) {
    const e = navigator.userAgent,
      a = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Opera Mini|IEMobile/i.test(e),
      d = /iPhone/i.test(e);
    a && has.add("esri-mobile", a), d && has.add("esri-iPhone", d), has.add("esri-geolocation", !!navigator.geolocation);
  }
  has.add("esri-wasm", "WebAssembly" in globalThis), has.add("esri-performance-mode-frames-between-render", 20), has.add("esri-force-performance-mode", !1), has.add("esri-shared-array-buffer", () => {
    const e = ("SharedArrayBuffer" in globalThis),
      a = !1 === globalThis.crossOriginIsolated;
    return e && !a;
  }), has.add("wasm-simd", () => {
    const e = [0, 97, 115, 109, 1, 0, 0, 0, 1, 5, 1, 96, 0, 1, 123, 3, 2, 1, 0, 10, 10, 1, 8, 0, 65, 0, 253, 15, 253, 98, 11];
    return WebAssembly.validate(new Uint8Array(e));
  }), has.add("esri-atomics", "Atomics" in globalThis), has.add("esri-workers", "Worker" in globalThis), has.add("web-feat:cache", "caches" in globalThis), has.add("esri-workers-arraybuffer-transfer", !has("safari") || Number(has("safari")) >= 12), has.add("workers-pool-size", 8), has.add("featurelayer-simplify-thresholds", [.5, .5, .5, .5]), has.add("featurelayer-simplify-payload-size-factors", [1, 1, 4]), has.add("featurelayer-fast-triangulation-enabled", !0), has.add("featurelayer-animation-enabled", !0), has.add("featurelayer-snapshot-enabled", !0), has.add("featurelayer-snapshot-point-min-threshold", 8e4), has.add("featurelayer-snapshot-point-max-threshold", 4e5), has.add("featurelayer-snapshot-point-coverage", .1), has.add("featurelayer-query-max-depth", 4), has.add("featurelayer-query-pausing-enabled", !1), has.add("featurelayer-advanced-symbols", !1), has.add("featurelayer-pbf", !0), has.add("featurelayer-pbf-statistics", !1), has.add("feature-layers-workers", !0), has.add("feature-polyline-generalization-factor", 1), has.add("mapview-transitions-duration", 200), has.add("mapview-essential-goto-duration", 200), has.add("mapview-srswitch-adjust-rotation-scale-threshold", 24e6), has.add("mapserver-pbf-version-support", 10.81), has.add("mapservice-popup-identify-max-tolerance", 20), has("host-webworker") || has("host-browser") && (has.add("esri-csp-restrictions", () => {
    try {
      new Function();
    } catch {
      return !0;
    }
    return !1;
  }), has.add("esri-image-decode", () => {
    if ("decode" in new Image()) {
      const e = new Image();
      return e.src = 'data:image/svg+xml;charset=UTF-8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg"></svg>', void e.decode().then(() => {
        has.add("esri-image-decode", !0, !0, !0);
      }).catch(() => {
        has.add("esri-image-decode", !1, !0, !0);
      });
    }
    return !1;
  }), has.add("esri-url-encodes-apostrophe", () => {
    const e = window.document.createElement("a");
    return e.href = "?'", e.href.includes("?%27");
  }));
})();
export { has as default };