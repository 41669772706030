/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import n from "./RandomLCG.js";
const t = 1.5,
  e = 1 / t,
  r = .5;
function o(n, t) {
  if (!t) return n;
  for (const e of t) n.push(e);
  return n;
}
function l(n) {
  if (!n) return;
  return n.length > 0 ? n[0] : void 0;
}
function u(n) {
  if (!n) return;
  const t = n.length;
  return t > 0 ? n[t - 1] : void 0;
}
function f(n) {
  return n;
}
function i(n, t = f) {
  if (!n || 0 === n.length) return;
  let e = n[0],
    r = t(e);
  for (let o = 1; o < n.length; ++o) {
    const l = n[o],
      u = Number(t(l));
    u > r && (r = u, e = l);
  }
  return e;
}
function c(n, t = f) {
  return i(n, n => -t(n));
}
function s(n, t) {
  return t ? n.filter((n, e, r) => r.findIndex(t.bind(null, n)) === e) : Array.from(new Set(n));
}
function h(n, t, e) {
  if (null == n && null == t) return !0;
  if (null == n || null == t || n.length !== t.length) return !1;
  if (e) {
    for (let r = 0; r < n.length; r++) if (!e(n[r], t[r])) return !1;
  } else for (let r = 0; r < n.length; r++) if (n[r] !== t[r]) return !1;
  return !0;
}
function a(n, t) {
  let e = n.length !== t.length;
  e && (n.length = t.length);
  for (let r = 0; r < t.length; ++r) n[r] !== t[r] && (n[r] = t[r], e = !0);
  return e;
}
function g(n, t, e) {
  let r, o;
  return e ? (r = t.filter(t => !n.some(n => e(n, t))), o = n.filter(n => !t.some(t => e(t, n)))) : (r = t.filter(t => !n.includes(t)), o = n.filter(n => !t.includes(n))), {
    added: r,
    removed: o
  };
}
function d(n, t, e) {
  return n && t ? e ? n.filter(n => t.findIndex(t => e(n, t)) > -1) : n.filter(n => t.includes(n)) : [];
}
function m(n) {
  return n && "number" == typeof n.length;
}
function p(n, t) {
  const e = n.length;
  if (0 === e) return [];
  const r = [];
  for (let o = 0; o < e; o += t) r.push(n.slice(o, o + t));
  return r;
}
function M(n, t) {
  void 0 === t && (t = n, n = 0);
  const e = new Array(t - n);
  for (let r = n; r < t; r++) e[r - n] = r;
  return e;
}
function w(n, t, e) {
  const r = n.length;
  let o = 0,
    l = r - 1;
  for (; o < l;) {
    const e = o + Math.floor((l - o) / 2);
    t > n[e] ? o = e + 1 : l = e;
  }
  const u = n[o];
  return e ? t >= n[r - 1] ? -1 : u === t ? o : o - 1 : u === t ? o : -1;
}
function x(n, t, e) {
  if (!n || 0 === n.length) return;
  const r = n.length - 1,
    o = n[0];
  if (t <= e(o)) return o;
  const l = n[r];
  if (t >= e(l)) return l;
  let u = 0,
    f = 0,
    i = r;
  for (; u < i;) {
    f = u + Math.floor((i - u) / 2);
    const o = n[f],
      l = e(o);
    if (l === t) return o;
    if (t < l) {
      if (f > 0) {
        const r = n[f - 1],
          u = e(r);
        if (t > u) return t - u >= l - t ? o : r;
      }
      i = f;
    } else {
      if (f < r) {
        const r = n[f + 1],
          u = e(r);
        if (t < u) return t - l >= u - t ? r : o;
      }
      u = f + 1;
    }
  }
  return n[f];
}
class v {
  constructor() {
    this.last = 0;
  }
}
const b = new v();
function y(n, t, e, r) {
  r = r || b;
  const o = Math.max(0, r.last - 10);
  for (let u = o; u < e; ++u) if (n[u] === t) return r.last = u, u;
  const l = Math.min(o, e);
  for (let u = 0; u < l; ++u) if (n[u] === t) return r.last = u, u;
  return -1;
}
function S(n, t, e, r) {
  const o = e ?? n.length,
    l = y(n, t, o, r);
  if (-1 !== l) return n[l] = n[o - 1], null == e && n.pop(), t;
}
const z = new Set();
function A(n, t, e = n.length, r = t.length, o, l) {
  if (0 === r || 0 === e) return e;
  z.clear();
  for (let f = 0; f < r; ++f) z.add(t[f]);
  o = o || b;
  const u = Math.max(0, o.last - 10);
  for (let f = u; f < e; ++f) if (z.has(n[f]) && (l?.push(n[f]), z.delete(n[f]), n[f] = n[e - 1], --e, --f, 0 === z.size || 0 === e)) return z.clear(), e;
  for (let f = 0; f < u; ++f) if (z.has(n[f]) && (l?.push(n[f]), z.delete(n[f]), n[f] = n[e - 1], --e, --f, 0 === z.size || 0 === e)) return z.clear(), e;
  return z.clear(), e;
}
function I(n, t) {
  let e = 0;
  for (let r = 0; r < n.length; ++r) {
    const o = n[r];
    t(o, r) && (n[e] = o, e++);
  }
  n.length = e;
}
function j(n, t, e) {
  const r = n.length;
  if (t >= r) return n.slice(0);
  const o = C(e),
    l = new Set(),
    u = [];
  for (; u.length < t;) {
    const t = Math.floor(o() * r);
    l.has(t) || (l.add(t), u.push(n[t]));
  }
  return u;
}
function C(n) {
  return n ? (G.seed = n, () => G.getFloat()) : Math.random;
}
function F(n, t) {
  const e = C(t);
  for (let r = n.length - 1; r > 0; r--) {
    const t = Math.floor(e() * (r + 1)),
      o = n[r];
    n[r] = n[t], n[t] = o;
  }
  return n;
}
const G = new n();
function L(n, t) {
  const e = n.indexOf(t);
  return -1 !== e ? (n.splice(e, 1), t) : null;
}
function N(n, t) {
  const e = new Map(),
    r = n.length;
  for (let o = 0; o < r; o++) {
    const r = n[o],
      l = t(r, o, n),
      u = e.get(l);
    u ? u.push(r) : e.set(l, [r]);
  }
  return e;
}
function O(n, t) {
  return null != n;
}
const R = [];
export { v as PositionHint, o as addMany, x as binaryFindClosest, w as binaryIndexOf, g as difference, R as emptyArray, h as equals, I as filterInPlace, l as first, N as groupToMap, y as indexOf, d as intersect, m as isArrayLike, O as isSome, u as last, i as max, c as min, j as pickRandom, M as range, t as reallocGrowthFactor, e as reallocShrinkFactor, r as reallocShrinkThreshold, L as remove, S as removeUnordered, A as removeUnorderedMany, F as shuffle, p as splitIntoChunks, s as unique, a as update };